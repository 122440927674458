import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";

import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazyload";

import { withRouter } from "react-router-dom";
// import Customization from "../../CategoryItemsV1/Customization";
import FloatCart from "../../FloatCart";
import BackWithSearch from "../../Elements/BackWithSearch";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import { getLinkedItems } from "../../../../services/restaurant/actions";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import Variations from "../../CategoryItemsV1/Variations";
import Customization from "../../Items/Customization";
import { setFavoriteItem } from "../../../../services/items/actions";

class ItemLinkedList extends Component {
	state = {
		update: true,
		total: null,
		linked_items: [],
		loading: false,
		loading_more: true,
		no_linked_items: false,
		google_script_loaded: false,
        position_latitude: null,
        position_longitude: null,
	};

	componentDidMount() {
		const location = navigator && navigator.geolocation;
		console.log("LOCATION", location);
		this.setState({ gps_loading: true });
		if (location) {
			location.getCurrentPosition(
				(position) => {
                    this.setState({
                        position_latitude: position.coords.latitude,
                        position_longitude: position.coords.longitude
                    });

                    this.__getLinkedItems(this.props.match.params.slug);
				},
				(error) => {
					this.setState({ gps_loading: false });
					console.log(error);
					alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
				},
				{ timeout: 5000 }
			);
		}

	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState !== this.state.data) {
			return true;
		} else {
			return false;
		}
	}

	__getLinkedItems = (slug) => {
		// if (localStorage.getItem("userSetAddress")) {
			this.setState({
				loading: true,
			});

            if(localStorage.getItem("userSetAddress")) {
                const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
                var userLatitude = userSetAddress.lat;
                var userLongitude = userSetAddress.lng;
            } else {
                var userLatitude = this.state.position_latitude;
                var userLongitude = this.state.position_longitude;
            }

			this.props.getLinkedItems(userLatitude, userLongitude, false, slug).then((linked_items) => {
               
				if (linked_items && linked_items.payload.length) {
                    window.location.href = "/stores/" + linked_items.payload[0].restaurant_slug + "/" + linked_items.payload[0].id;
                    return;
					this.setState({
                        linked_items: linked_items.payload,
						total: linked_items.payload.length,
						no_linked_items: false,
						loading: false,
						loading_more: false,
					});
				} else {
					this.setState({
						total: null,
						no_linked_items: true,
					});
				}
			});
		// }
	};

	forceStateUpdate = () => {
        this.forceUpdate();
        // this.props.forceStateUpdate();
		setTimeout(() => {
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
	};

    __getSalePrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.sale_price !== undefined) {
            let sale_price = variant.sale_price;
            return parseFloat(sale_price).toFixed(0);
        }
        return 0;
    }

    __getRegularPrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.regular_price !== undefined) {
            let sale_price = variant.sale_price;
            let regular_price = variant.regular_price;
            if(sale_price !== regular_price) {
                return parseFloat(regular_price).toFixed(0);   
            }
        }
        return false;
    }

    __getDiscountPercentage = (item) => {
        var discount = 0;
        if(item.has_variant) {
            let sale_price = this.__getSalePrice(item.variants);
            let regular_price = this.__getRegularPrice(item.variants);
            if(parseFloat(sale_price) >= parseFloat(regular_price)) {
                return false;
            }
            discount = ( ( parseFloat(regular_price) - parseFloat(sale_price) ) / parseFloat(regular_price) ) * 100;
        }
        return discount !== 0 ? discount.toFixed(0) : false;
    }

	setFavoriteItem = (single_item, event) => {
		const { user } = this.props;
		if (user.success) {
			event.target.classList.toggle("is-active");
			this.props.setFavoriteItem(user.data.auth_token, single_item.id).then(res => this.props.updateRestaurantItemsState(true));
		}
	};

    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }

    __itemAvailability = (item) => {
        if(!item.has_variant) return true;

        if(item.variant_type === "P") {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && variant.stock <= 0 && !variant.is_infinite && !variant.is_negative;
            });
            return is_item_out_of_stock.length ? false : true;
        } else {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && (variant.is_infinite || variant.is_negative);
            });
            if(is_item_out_of_stock.length > 0) { return true; }
            return item.variant_stock > 0;
        }
    }

	render() {
		const { addProduct, removeProduct, cartProducts, forceRestaurantItemsUpdate } = this.props;

        const isItemExistInCart = (item_id) => {
            let cc = cartProducts.find(cartItem => {
                return cartItem.id === item_id
            });
            if(cc !== undefined) {
                return true;
            }
            return false;
        }

		const { history, user } = this.props;
        const restaurant = this.props.match.params.restaurant;

        if(forceRestaurantItemsUpdate === true) {
            const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
			this.props.getLinkedItems(userSetAddress.lat, userSetAddress.lng).then((linked_items) => {
				if (linked_items && linked_items.payload.length) {
					this.setState({
						total: linked_items.payload.length,
						no_linked_items: false,
						loading: false,
						loading_more: false,
					});
				} else {
					this.setState({
						total: null,
						no_linked_items: true,
					});
				}
			});
            this.props.updateRestaurantItemsState(false);
        }

		return (
			<React.Fragment>
                <div className="bg-white">        
                    <React.Fragment>                         
                        <BackWithSearch
                            ref={(node) => {
                                this.child = node;
                            }}
                            history={history}
                            boxshadow={false}
                            has_restaurant_info={false}
                            restaurant={restaurant}
                            disable_search={true}
                            homeButton={true}
                            shareButton={true}
                        />
                    </React.Fragment>
                </div>
				<div className={"bg-white mt-50 mb-30" + (restaurant !== undefined ? ' pt-50' : '')}>
					{this.state.loading ? (
						<ContentLoader
							height={378}
							width={400}
							speed={1.2}
							primaryColor="#f3f3f3"
							secondaryColor="#ecebeb"
						>
							<rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

							<rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

							<rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
							<rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
							<rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
						</ContentLoader>
					) : (
						<React.Fragment>
                            <div className="col-sm-12 frequently-bought-content-section mt-15">
                                {this.state.linked_items.length === 0 ? (
                                    <ContentLoader
                                        height={378}
                                        width={400}
                                        speed={1.2}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb"
                                    >
                                        <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                        <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                                        <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                                        <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                                        <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                                        <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                                        <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                                        <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                                        <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                                    </ContentLoader>
                                ) : (
                                    <React.Fragment>
                                    {this.state.linked_items.map((item) => (
										<React.Fragment key={item.id}>
                                            
                                            <hr />
                                            <div style={{ 
                                                position: 'relative'
                                                }}>
                                                {!this.__itemAvailability(item) && (
                                                    <div className="blur-content-list">{localStorage.getItem('outOfStockText')}</div>
                                                )}
                                                <div className={`category-list-item ${!this.__itemAvailability(item) ? ('blur-content-section') : ''}`}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}>
                                                    {this.__getDiscountPercentage(item) ? (
                                                        <div className="item-discount-tag">
                                                            <img alt="item-discount" src="../../assets/img/various/discount-ribbon.png" />
                                                            <span className="discount-price">{ this.__getDiscountPercentage(item) }% OFF</span>
                                                        </div>
                                                    ) : (
                                                        <React.Fragment />
                                                    )}
                                                    {item.image !== null && (
                                                        <React.Fragment>
                                                            <Link to={"../stores/" + item.restaurant_slug + "/" + item.id}>
                                                                <React.Fragment>
                                                                    {this.state.searching ? (
                                                                        <img
                                                                            src={item.image}
                                                                            alt={item.name}
                                                                            className="flex-item-image"
                                                                        />
                                                                    ) : (
                                                                        <LazyLoad>
                                                                            <ProgressiveImage
                                                                                src={item.image}
                                                                                placeholder="/assets/img/various/blank-white.jpg"
                                                                            >
                                                                                {(src, loading) => (
                                                                                    <img
                                                                                        style={{
                                                                                            opacity: loading ? "0.5" : "1",
                                                                                        }}
                                                                                        src={src}
                                                                                        alt={item.name}
                                                                                        className="flex-item-image"
                                                                                    />
                                                                                )}
                                                                            </ProgressiveImage>
                                                                        </LazyLoad>
                                                                    )}
                                                                </React.Fragment>
                                                                {localStorage.getItem("showVegNonVegBadge") === "true" &&
                                                                    item.is_veg !== null && (
                                                                        <React.Fragment>
                                                                            {item.is_veg ? (
                                                                                <img
                                                                                    src="/assets/img/various/veg-icon-bg.png"
                                                                                    alt="Veg"
                                                                                    className="mr-1 veg-non-veg-badge"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src="/assets/img/various/non-veg-icon-bg.png"
                                                                                    alt="Non-Veg"
                                                                                    className="mr-1 veg-non-veg-badge"
                                                                                />
                                                                            )}
                                                                        </React.Fragment>
                                                                    )}
                                                            </Link>
                                                        </React.Fragment>
                                                    )}
                                                    <Link className="flex-item-name ml-12" to={"../stores/" + item.restaurant_slug + "/" + item.id}>
                                                        <div
                                                            className={
                                                                item.image !== null ? "flex-item-name ml-12" : "flex-item-name"
                                                            }
                                                        >
                                                            {item.image === null && (
                                                                <React.Fragment>
                                                                    {localStorage.getItem("showVegNonVegBadge") === "true" &&
                                                                        item.is_veg !== null && (
                                                                            <React.Fragment>
                                                                                {item.is_veg ? (
                                                                                    <img
                                                                                        src="/assets/img/various/veg-icon-bg.png"
                                                                                        alt="Veg"
                                                                                        className="mr-1 veg-non-veg-badge-noimage"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src="/assets/img/various/non-veg-icon-bg.png"
                                                                                        alt="Non-Veg"
                                                                                        className="mr-1 veg-non-veg-badge-noimage"
                                                                                    />
                                                                                )}
                                                                            </React.Fragment>
                                                                        )}
                                                                </React.Fragment>
                                                            )}
                                                            <span className="item-name">
                                                                {item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}
                                                            </span>{" "}
                                                            {item.has_variant ? (                                                    
                                                                <span className="item-subtitle text-normal" style={{ 
                                                                    visibility: "hidden"
                                                                }}>                                                                
                                                                    {item.variants.find(function(o) { return o.is_default === 1 } ).name}
                                                                </span>
                                                            ) : (                                                
                                                                <span className="item-subtitle text-normal">&nbsp;</span>
                                                            )}
                                                            {/* <ItemBadge item={item} /> */}
                                                            <div className="item-color-price font-w600 text-left mt-10">   
                                                                {item.has_variant ? (       
                                                                    <React.Fragment>
                                                                        <span className="sale-price">                                                                
                                                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                localStorage.getItem("currencyFormat")}{" "}
                                                                                {this.__getSalePrice(item.variants)}
                                                                        </span>     
                                                                        {this.__getRegularPrice(item.variants) && (                                          
                                                                            <span className="regular-price">       
                                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                    localStorage.getItem("currencyFormat")}
                                                                                    <s>{this.__getRegularPrice(item.variants)}</s>
                                                                            </span>  
                                                                        )}
                                                                    </React.Fragment>                                             
                                                                ) : (                                                    
                                                                    <span className="sale-price">
                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                            localStorage.getItem("currencyFormat")}{" "}
                                                                            {this.__formatPrice(item.price)}
                                                                        {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                                            localStorage.getItem("currencyFormat")}
                                                                    </span>
                                                                )}     
                                                            </div>
                                                            <div className="inclusive-tax-text">{localStorage.getItem('inclusiveTaxText')}</div>
                                                        </div>
                                                    </Link>

                                                    <div className="item-actions pull-right pb-0 mt-50">                                                    
                                                        {user.success && (
                                                            <span
                                                                onClick={(event) => this.setFavoriteItem(item, event)}
                                                                className={`item-favorite text-right heart ${item.is_favorited && "is-active"} heartIcon` + item.id}
                                                            />
                                                        )}
                                                        {item.is_active ? (
                                                            <React.Fragment>
                                                                {isItemExistInCart(item.id) ? (
                                                                    <React.Fragment>
                                                                        {item.has_variant && item.variants.length > 0 ? (
                                                                            <Variations
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                cartProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                addByDefault={true}
                                                                                showDefaultVariant={true}
                                                                            />
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="btn-group btn-group-sm"
                                                                                    role="group"
                                                                                    aria-label="btnGroupIcons1"
                                                                                >
                                                                                    {item.addon_categories.length ? (
                                                                                        <button
                                                                                            disabled
                                                                                            type="button"
                                                                                            className="btn btn-add-remove"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                        >
                                                                                            <span className="btn-dec">-</span>
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                removeProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            <span className="btn-dec">-</span>
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}

                                                                                    <input type="text" className="textbox-qty" disabled value={ cartProducts.find(
                                                                                            (cp) =>
                                                                                                cp.id ===
                                                                                                item.id
                                                                                        ).quantity} />
                    
                                                                                    {item.addon_categories.length ? (
                                                                                        <Customization
                                                                                            product={item}
                                                                                            addProduct={addProduct}
                                                                                            forceUpdate={this.forceStateUpdate}
                                                                                        />
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            <span className="btn-inc">+</span>
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        {item.has_variant && item.variants.length > 0 ? (
                                                                            <Variations
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                cartProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                addByDefault={true}
                                                                                showDefaultVariant={true}
                                                                            />
                                                                        ): (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="btn-group btn-group-sm"
                                                                                    role="group"
                                                                                    aria-label="btnGroupIcons1"
                                                                                >
                                                                                    {item.addon_categories.length ? (
                                                                                        <Customization
                                                                                            product={item}
                                                                                            addProduct={addProduct}
                                                                                            forceUpdate={this.forceStateUpdate}
                                                                                        />
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove btn-custom-add"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            Add
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="text-danger text-item-not-available">
                                                                {localStorage.getItem("cartItemNotAvailable")}
                                                            </div>
                                                        )}
                                                        
                                                        {item.addon_categories.length > 0 && (
                                                            <React.Fragment>
                                                                <span
                                                                    className="customizable-item-text d-block text-center"
                                                                    style={{
                                                                        color: localStorage.getItem("storeColor"),
                                                                    }}
                                                                >
                                                                    {localStorage.getItem("customizableItemText")}
                                                                </span>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
										</React.Fragment>
									))}
                                    </React.Fragment>                                    
                                )}
                            </div>
						</React.Fragment>
					)}
                
                    <div>
                        <FloatCart forceUpdate={this.forceStateUpdate} />
                    </div>

					{this.state.loading_more ? (
						<div className="">
							<ContentLoader
								height={120}
								width={400}
								speed={1.2}
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
							>
								<rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
								<rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
								<rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
							</ContentLoader>
						</div>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	cartProducts: state.cart.products,
	user: state.user.user
});

export default withRouter(
	connect(
		mapStateToProps,
		{
            getLinkedItems,
            addProduct, 
            removeProduct,            
            setFavoriteItem
		}
	)(ItemLinkedList)
);
