import {
	CHECK_RESTAURANT_OPERATION_SERVICE,
	GET_RESTAURANTS_BASED_ON_CATEGORY,
	GET_RESTAURANTS_CATEGORIES,
	GET_RESTAURANTS_ITEM_CATEGORIES,
	GET_RESTAURANTS_DEAL_ITEMS,
	GET_RESTAURANTS_COMBOS,
	GET_RESTAURANTS_FEATURED_ITEMS,
	GET_DELIVERY_RESTAURANTS,
	GET_SELFPICKUP_RESTAURANTS,
	GET_FAVORITE_RESTAURANTS,
	GET_FAVORITE_ITEMS,
	GET_LINKED_ITEMS
} from "./actionTypes";

const initialState = {
	restaurants: [],
	is_operational: "",
	filtered_restaurants: [],
	restaurants_categories: [],
	restaurants_item_categories: [],
	restaurants_deal_items: [],
	restaurants_combos: [],
	restaurants_featured_items: [],
	favoriteRestaurants: [],
	favoriteItems: [],
	linkedItems: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case CHECK_RESTAURANT_OPERATION_SERVICE:
			return { ...state, is_operational: action.payload };
		case GET_RESTAURANTS_BASED_ON_CATEGORY:
			return { ...state, filtered_restaurants: action.payload };
		case GET_RESTAURANTS_CATEGORIES:
			return { ...state, restaurants_categories: action.payload };
		case GET_RESTAURANTS_ITEM_CATEGORIES:
			return { ...state, restaurants_item_categories: action.payload };
		case GET_RESTAURANTS_DEAL_ITEMS:
			return { ...state, restaurants_deal_items: action.payload };
		case GET_RESTAURANTS_COMBOS:
			return { ...state, restaurants_combos: action.payload };
		case GET_RESTAURANTS_FEATURED_ITEMS:
			return { ...state, restaurants_featured_items: action.payload };

		case GET_DELIVERY_RESTAURANTS:
			return { ...state, restaurants: action.payload };

		case GET_SELFPICKUP_RESTAURANTS:
			return { ...state, restaurants: action.payload };
			
		case GET_FAVORITE_RESTAURANTS:
			return { ...state, favoriteRestaurants: action.payload };
			
		case GET_FAVORITE_ITEMS:
			return { ...state, favoriteItems: action.payload };
			
		case GET_LINKED_ITEMS:
			return { ...state, linkedItems: action.payload };

		default:
			return state;
	}
}
