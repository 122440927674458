import React, { Component } from "react";

import Ink from "react-ink";

import Modal from "react-responsive-modal";
import { connect } from "react-redux";

// import FloatCart from "../../FloatCart";
import { addProduct, removeProduct } from "../../../../services/cart/actions";

class Variations extends Component {
	state = {
        update: true,
        open: false,
    };

    handlePopupOpen = () => {
        if(this.state.open) {
            this.handlePopupClose();
        }
		this.setState({ open: true });
	};

    forceStateUpdate = () => {
        this.props.forceUpdate();
		setTimeout(() => {
			if (this.state.update) {
				this.setState({ update: false });
			} else {
				this.setState({ update: true });
			}
		}, 100);
	};

    addProductVariant = (product, variant) => {
        if(variant) {
            product.selectedvariant = variant;
            // product.name += variant.name;
            product.price = variant.sale_price;
        }
        product.quantity = 1;
		this.props.addProduct(product);
	};

    
    handlePopupClose = () => {
        this.setState({ open: false });
        // this.props.forceUpdate();
    };

    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }

    __itemAvailability = (item, variant = 0) => {
        if(!item.has_variant) return true;

        if(item.variant_type === "P") {
            if(variant !== undefined) {
                return (variant.stock > 0 || variant.is_infinite || variant.is_negative);
            }

            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && variant.stock <= 0 && !variant.is_infinite && !variant.is_negative;
            });
            return is_item_out_of_stock.length ? false : true;
        } else {
            if(variant !== undefined) {
                if(!variant.is_infinite && !variant.is_negative) {
                    return item.variant_stock > 0;
                }
                return true;
            }

            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && (variant.is_infinite || variant.is_negative);
            });
            if(is_item_out_of_stock.length > 0) { return true; }
            
            return item.variant_stock > 0;
        }
    }

    __getDiscountPercentage = (variant) => {
        if(parseFloat(variant.sale_price) >= parseFloat(variant.regular_price)) {
            return false;
        }
        let discount = ( ( parseFloat(variant.regular_price) - parseFloat(variant.sale_price) ) / parseFloat(variant.regular_price) ) * 100;
        return discount !== 0 ? discount.toFixed(0) : false;
    }

	render() {
		const { product, removeProduct, cartProducts, addByDefault, showDefaultVariant, hideAddController } = this.props;

        const isItemExistInCart = (item_id, variant_id) => {
            const { cartProducts } = this.props;
            let cc = cartProducts.find(cartItem => {
                if(cartItem.selectedvariant !== undefined) {
                    return cartItem.id === item_id && cartItem.selectedvariant.id === variant_id;
                }
            });
            if(cc !== undefined) {
                return true;
            }
            return false;
        }

        const defaultVariant = product.variants.find(variant => variant.is_default === 1);
        const totalAvailableVariants = product.variants.filter(variant => (variant.stock > 0 || variant.is_infinite || variant.is_negative));

		return (
			<React.Fragment>
                { addByDefault === true ? (
                    <React.Fragment>
                        { showDefaultVariant && (
                            <div className="position-relative">
                                <div className="item-variant-actions pb-0 mb-2">
                                    {product.variants.length === 1 || totalAvailableVariants.length === 1 ? (
                                        <React.Fragment>
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                            >
                                                
                                                {defaultVariant.name} &nbsp;
                                                <Ink duration="500" />
                                            </button>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={this.handlePopupOpen}
                                            >
                                                
                                                {defaultVariant.name} <i className="si si-arrow-down"></i>
                                                <Ink duration="500" />
                                            </button>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        )}
                        {hideAddController === true && !this.__itemAvailability(product, defaultVariant) ? (
                            <div className="blur-content-single">{localStorage.getItem('outOfStockText')}</div>   
                        ) : (
                            <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="btnGroupIcons1"
                            >
                                {isItemExistInCart(product.id, defaultVariant.id) ? (
                                    <React.Fragment key={defaultVariant.id}>
                                        <button
                                            type="button"
                                            className="btn btn-add-remove"
                                            style={{
                                                color: localStorage.getItem("cartColor-bg"),
                                                backgroundColor: localStorage.getItem("cartColorBg"),
                                            }}
                                            onClick={() => {
                                                product.quantity = 1;
                                                product.selectedvariant = defaultVariant;
                                                removeProduct(product);
                                                this.forceStateUpdate();
                                            }}
                                        >
                                            <span className="btn-dec">-</span>
                                            <Ink duration="500" />
                                        </button>                                                                

                                        <input 
                                            type="text" 
                                            disabled 
                                            value={ cartProducts.find(
                                                (cp) =>
                                                    cp.id ===
                                                    product.id && cp.selectedvariant.id === defaultVariant.id
                                            ).quantity} 
                                            data-variant-id={defaultVariant.id}
                                            data-variant-name={defaultVariant.name}
                                            data-variant-price={defaultVariant.price}
                                            className="textbox-qty"
                                        />

                                        <button
                                            type="button"
                                            className="btn btn-add-remove"
                                            style={{
                                                color: localStorage.getItem("cartColor-bg"),
                                                backgroundColor: localStorage.getItem("cartColorBg")
                                            }}
                                            onClick={() => {
                                                this.addProductVariant(product, defaultVariant);
                                                this.forceStateUpdate();
                                            }}
                                        >
                                            <span className="btn-inc">+</span>
                                            <Ink duration="500" />
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <button
                                            type="button"
                                            className="btn btn-add-remove btn-custom-add"
                                            style={{
                                                color: localStorage.getItem("cartColor-bg"),
                                                backgroundColor: localStorage.getItem("cartColorBg"),
                                            }}
                                            onClick={() => {
                                                this.addProductVariant(product, defaultVariant);
                                                this.forceStateUpdate();
                                            }}
                                        >
                                            Add
                                        <Ink duration="500" />
                                    </button>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="item-variant-actions pb-0 mt-2">
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.handlePopupOpen}
                            >
                                
                                {product.variants.find(function(o) { return o.is_default === 1 } ).name} <i className="si si-arrow-down"></i>
                                <Ink duration="500" />
                            </button>
                        </div>
                        <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="btnGroupIcons1"
                        >
                            <button
                                type="button"
                                className="btn btn-add-remove btn-custom-add"
                                style={{
                                    color: localStorage.getItem("cartColor-bg"),
                                    backgroundColor: localStorage.getItem("cartColorBg"),
                                }}
                                onClick={this.handlePopupOpen}
                            >
                                    Add
                                <Ink duration="500" />
                            </button>
                        </div>
                    </React.Fragment>
                ) }
				<Modal open={this.state.open} onClose={this.handlePopupClose} closeIconSize={32}>
					<div
						style={{
							textAlign: "left",
						}}
					>
						<h4 className="mb-2">{localStorage.getItem("productVariationHeading")}</h4>
						<hr className="mt-10" style={{ borderColor: "#ccc" }} />
						{product.variants.map((variant, index) => (
                            <React.Fragment key={variant.id}>
                                <div className="single-variant mt-10" style={{ 
                                    display: "flex",
                                    justifyContent: "space-between",
                                    position: "relative"
                                 }}>
                                    <label className="text addon-label" htmlFor={variant.name}>
                                        <span className="variant-name">{variant.name}</span> <br />
                                        <span className="item-color-price">
                                            <span className="sale-price">
                                            {localStorage.getItem("hidePriceWhenZero") === "true" &&
                                            variant.sale_price === "0.00" ? null : (
                                                <React.Fragment>
                                                    {localStorage.getItem("currencySymbolAlign") ===
                                                        "left" &&
                                                        localStorage.getItem("currencyFormat")}
                                                    <strong>{this.__formatPrice(variant.sale_price)}</strong>{" "}
                                                    {localStorage.getItem("currencySymbolAlign") ===
                                                        "right" &&
                                                        localStorage.getItem("currencyFormat")}
                                                </React.Fragment>
                                            )}
                                            </span>
                                            {variant.regular_price !== variant.sale_price && (
                                                <span className="regular-price">
                                                    {localStorage.getItem("hidePriceWhenZero") === "true" &&
                                                    variant.regular_price === "0.00" ? null : (
                                                        <React.Fragment>
                                                            {localStorage.getItem("currencySymbolAlign") ===
                                                                "left" &&
                                                                localStorage.getItem("currencyFormat")}
                                                            <s><strong>{this.__formatPrice(variant.regular_price)}</strong>{" "}</s>
                                                            {localStorage.getItem("currencySymbolAlign") ===
                                                                "right" &&
                                                                localStorage.getItem("currencyFormat")}
                                                        </React.Fragment>
                                                    )}
                                                </span>
                                            )}
                                            <span className="discount-percent ml-5"
                                                style={{
                                                    visibility: !this.__getDiscountPercentage(variant) ? "hidden" : "" 
                                                }}
                                            >
                                                {this.__getDiscountPercentage(variant)}% OFF
                                            </span>
                                        </span>
                                    </label>

                                    {!this.__itemAvailability(product, variant) ? (
                                        <div className="blur-content-single-variant">{localStorage.getItem('outOfStockText')}</div>   
                                    ) : (
                                        <div className="item-actions pull-right">
                                            <div
                                                className="btn-group btn-group-sm"
                                                role="group"
                                                aria-label="btnGroupIcons1"
                                            >
                                                {isItemExistInCart(product.id, variant.id) ? (
                                                    <React.Fragment key={variant.id}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-add-remove"
                                                            style={{
                                                                color: localStorage.getItem("cartColor-bg"),
                                                                backgroundColor: localStorage.getItem("cartColorBg"),
                                                            }}
                                                            onClick={() => {
                                                                product.quantity = 1;
                                                                product.selectedvariant = variant;
                                                                removeProduct(product);
                                                                this.forceStateUpdate();
                                                            }}
                                                        >
                                                            <span className="btn-dec">-</span>
                                                            <Ink duration="500" />
                                                        </button>                                                                

                                                        <input 
                                                            type="text" 
                                                            disabled 
                                                            value={ cartProducts.find(
                                                                (cp) =>
                                                                    cp.id ===
                                                                    product.id && cp.selectedvariant.id === variant.id
                                                            ).quantity} 
                                                            data-variant-id={variant.id}
                                                            data-variant-name={variant.name}
                                                            data-variant-price={variant.price}
                                                            className="textbox-qty"
                                                        />

                                                        <button
                                                            type="button"
                                                            className="btn btn-add-remove"
                                                            style={{
                                                                color: localStorage.getItem("cartColor-bg"),
                                                                backgroundColor: localStorage.getItem("cartColorBg")
                                                            }}
                                                            onClick={() => {
                                                                this.addProductVariant(product, variant);
                                                                this.forceStateUpdate();
                                                            }}
                                                        >
                                                            <span className="btn-inc">+</span>
                                                            <Ink duration="500" />
                                                        </button>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <button
                                                                type="button"
                                                                className="btn btn-add-remove btn-custom-add"
                                                                style={{
                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                    backgroundColor: localStorage.getItem("cartColorBg"),
                                                                }}
                                                                onClick={() => {
                                                                    this.addProductVariant(product, variant);
                                                                    this.forceStateUpdate();
                                                                }}
                                                            >
                                                                Add
                                                            <Ink duration="500" />
                                                        </button>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>							
						))}
						<button
							className="btn btn-lg btn-customization-done mt-10"
							onClick={() => {
								this.handlePopupClose();
							}}
							style={{
								backgroundColor: localStorage.getItem("cartColorBg"),
								color: localStorage.getItem("cartColorText"),
							}}
						>
							{localStorage.getItem("productVariationDoneBtnText")}
						</button>
					</div>
				</Modal>
            </React.Fragment>
		);
	}
}

// export default Variations;

const mapStateToProps = (state) => ({
	cartProducts: state.cart.products
});

export default connect(
	mapStateToProps,
	{
		addProduct,
		removeProduct
	}
)(Variations);