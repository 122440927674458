import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import Ink from "react-ink";
// import LazyLoad from "react-lazyload";

import { Link, withRouter } from "react-router-dom";
// import Customization from "../../CategoryItemsV1/Customization";
import FloatCart from "../../FloatCart";
import BackWithSearch from "../../Elements/BackWithSearch";
// import Fade from "react-reveal/Fade";

import { connect } from "react-redux";
import { getRestaurantsDealItems } from "../../../../services/restaurant/actions";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import Variations from "../../CategoryItemsV1/Variations";
import Customization from "../../Items/Customization";
import { setFavoriteItem } from "../../../../services/items/actions";
import LazyLoad from "react-lazyload";
import ProgressiveImage from "react-progressive-image";

class DynamicFeaturedSectionStyle1 extends Component {  
	state = {
		update: true,
		total: null,
		loading: false,
		loading_more: true,
        filterText: null,
        filtered_featured_section: null,
        noResultText: null
	};  

	forceStateUpdate = () => {
        this.forceUpdate();
        this.props.forceStateUpdate();

		setTimeout(() => {
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
	};

    __getSalePrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.sale_price !== undefined) {
            let sale_price = variant.sale_price;
            return parseFloat(sale_price).toFixed(0);
        }
        return 0;
    }

    __getRegularPrice = (item_variants) => {        
        var variant = item_variants.find(function(o) { return o.is_default === 1 } );
        if(variant && variant !== undefined && variant.regular_price !== undefined) {
            let sale_price = variant.sale_price;
            let regular_price = variant.regular_price;
            if(sale_price !== regular_price) {
                return parseFloat(regular_price).toFixed(0);   
            }
        }
        return false;
    }

    __getDiscountPercentage = (item) => {
        var discount = 0;
        if(item.has_variant) {
            let sale_price = this.__getSalePrice(item.variants);
            let regular_price = this.__getRegularPrice(item.variants);
            if(parseFloat(sale_price) >= parseFloat(regular_price)) {
                return false;
            }
            discount = ( ( parseFloat(regular_price) - parseFloat(sale_price) ) / parseFloat(regular_price) ) * 100;
        }
        return discount !== 0 ? discount.toFixed(0) : false;
    }

    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }

    __itemAvailability = (item) => {
        if(!item.has_variant) return true;
        
        if(item.variant_type === "P") {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && variant.stock <= 0 && !variant.is_infinite && !variant.is_negative;
            });
            return is_item_out_of_stock.length ? false : true;
        } else {
            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && (variant.is_infinite || variant.is_negative);
            });
            if(is_item_out_of_stock.length > 0) { return true; }
            
            return item.variant_stock > 0;
        }
    }

	setFavoriteItem = (single_item, event) => {
		const { user } = this.props;
		if (user.success) {
			event.target.classList.toggle("is-active");
			this.props.setFavoriteItem(user.data.auth_token, single_item.id);
		}
	};

	searchForItem = (e) => {
		this.searchItem(e.target.value);
	};

	searchItem = (event) => {
		let filterText = event;        
        const { featured_section } = this.props;
        const filtered_items = featured_section.items.filter(item => item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
        const filtered_featured_section = {
            items: filtered_items
        };
        if(filtered_featured_section.items.length > 0) {
            this.setState({
                noResultText: null,
                filtered_featured_section: filtered_featured_section,
                filterText: filterText
            });
        } else {
            this.setState({
                noResultText: localStorage.getItem("itemSearchNoResultText") + ' ' + filterText,
                filtered_featured_section: null,
                filterText: null
            });
        }
	}

	render() {
		const { addProduct, removeProduct, cartProducts } = this.props;

        const isItemExistInCart = (item_id) => {
            let cc = cartProducts.find(cartItem => {
                return cartItem.id === item_id
            });
            if(cc !== undefined) {
                return true;
            }
            return false;
        }

		const { user, featured_section, history, section_type } = this.props;

        return (
            <React.Fragment>
                <div className={` ${section_type === undefined && 'dynamic-featured-sections' }`}>
                    { section_type === undefined && (
                        <div className="bg-white">                    
                            <React.Fragment>                         
                                <BackWithSearch
                                    ref={(node) => {
                                        this.child = node;
                                    }}
                                    history={history}
                                    boxshadow={false}
                                    disable_search={true}
                                    homeButton={true}
                                    shareButton={true}
                                />
                            </React.Fragment>
                        </div>
                    )}
                    {featured_section.image && (
                        <img width="100%" src={featured_section.image} alt={featured_section.title} />
                    )}
                    <div className="col-sm-12">
                        <div className="deal-title-section my-10">                                                      
                            <span className="title"><strong>{featured_section.title}</strong></span>
                            <span className="subtitle">{featured_section.subtitle}</span>

                            { section_type !== undefined && (
                                <DelayLink
                                    to={"dynamic-featured-sections/" + section_type}
                                    delay={200}
                                    className="block text-center mb-3 view-all"
                                >
                                    View All
                                    <i
                                        className="si si-arrow-right nav-location-icon ml-1"
                                    />
                                </DelayLink>
                            )}
                            {featured_section.image && localStorage.getItem("showDynamicFeaturedSectionSearch") == "true" && (
                                <div className="category-search-section mt-20">
                                    <div className="input-group" ref="searchGroup" onClick={this.inputFocus} style={{ 
                                        zIndex: 1
                                    }}>
                                        <img src="/assets/img/home/search-icon.png" alt="search-location" />
                                        <input
                                            type="text"
                                            className="form-control items-search-box"
                                            placeholder={localStorage.getItem("itemSearchPlaceholder")}
                                            onChange={this.searchForItem}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="deal-content-section">
                            {this.state.noResultText !== null ? (
                                <React.Fragment>
                                    <div className="category-search-section mt-20">
                                        {this.state.noResultText}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {this.state.filterText === null && featured_section.items.map((item, index) => (
                                        <React.Fragment key={item.id}>
                                            <div className="single-deal-section mb-25">
                                                {!this.__itemAvailability(item) && (
                                                    <div className="blur-content-grid">{localStorage.getItem('outOfStockText')}</div>
                                                )}
                                                <div className={`deal-content ${!this.__itemAvailability(item) ? 'blur-content-section' : ''}`}>
                                                    <div className="item-favorites m-5">
                                                        <span className="item-ribbon text-left"
                                                            style={{
                                                                visibility: !this.__getDiscountPercentage(item) ? "hidden" : "" 
                                                            }}
                                                        >
                                                            {this.__getDiscountPercentage(item) ? this.__getDiscountPercentage(item) : '&nbsp;'}% OFF
                                                        </span>
                                                        {user.success && (
                                                            <span
                                                                onClick={(event) => this.setFavoriteItem(item, event)}
                                                                className={`item-favorite text-right heart ${item.is_favorited && "is-active"} heartIcon` + item.id}
                                                            />
                                                        )}
                                                    </div>
                                                    <DelayLink
                                                        to={"../stores/" + item.restaurant_slug + "/" + item.id}
                                                        delay={200}
                                                    >
                                                        <div className="item-image-section">
                                                            <img
                                                                src={item.image}
                                                                alt={item.name}
                                                                className="slider-wrapper__img slider-cust-img"
                                                            />
                                                        </div>
                                                        <div className="item-details">
                                                            <div className="font-w600 text-left">
                                                                <span className="item-title">
                                                                    <strong>
                                                                        {item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}
                                                                    </strong>
                                                                </span>
                                                                {item.has_variant ? (                                                    
                                                                    <span className="item-subtitle text-normal" style={{ 
                                                                        visibility: "hidden"
                                                                        }}>                                                                
                                                                        {item.variants.find(function(o) { return o.is_default === 1 } ).name}
                                                                    </span>
                                                                ) : (                                                
                                                                    <span className="item-subtitle text-normal">&nbsp;</span>
                                                                )}
                                                            </div>
                                                            <div className="item-badge">
                                                                {localStorage.getItem("showVegNonVegBadge") === "true" && item.is_veg !== null && (
                                                                    <React.Fragment>
                                                                        {item.is_veg ? (
                                                                            <img
                                                                                src="/assets/img/various/veg-icon-bg.png"
                                                                                alt="Veg"
                                                                                className="mr-1 veg-non-veg-badge"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="/assets/img/various/non-veg-icon-bg.png"
                                                                                alt="Non-Veg"
                                                                                className="mr-1 veg-non-veg-badge"
                                                                            />
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                            <div className="item-color-price font-w600 text-left mt-10">   
                                                                {item.has_variant ? (       
                                                                    <React.Fragment>
                                                                        <span className="sale-price">                                                                
                                                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                localStorage.getItem("currencyFormat")}{" "}
                                                                                {this.__getSalePrice(item.variants)}
                                                                        </span>     
                                                                        {this.__getRegularPrice(item.variants) && (                                                   
                                                                            <span className="regular-price">       
                                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                    localStorage.getItem("currencyFormat")}
                                                                                    <s>{this.__getRegularPrice(item.variants)}</s>
                                                                            </span> 
                                                                        )} 
                                                                    </React.Fragment>                                             
                                                                ) : (                                                    
                                                                    <span>
                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                            localStorage.getItem("currencyFormat")}{" "}
                                                                            {this.__formatPrice(item.price)}
                                                                        {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                                            localStorage.getItem("currencyFormat")}
                                                                    </span>
                                                                )}     
                                                            </div>
                                                        </div>
                                                        <Ink duration="500" hasTouch={false} />
                                                    </DelayLink>
                                                
                                                    <div className="item-actions">
                                                        {item.is_active ? (
                                                            <React.Fragment>
                                                                {isItemExistInCart(item.id) ? (
                                                                    <React.Fragment>
                                                                        {item.has_variant && item.variants.length > 0 ? (
                                                                            <Variations
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                cartProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                doForceUpdate={this.state.update}
                                                                                addByDefault={true}
                                                                                showDefaultVariant={true}
                                                                            />
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="btn-group btn-group-sm"
                                                                                    role="group"
                                                                                    aria-label="btnGroupIcons1"
                                                                                >
                                                                                    {item.addon_categories.length ? (
                                                                                        <React.Fragment>
                                                                                            <button
                                                                                                disabled
                                                                                                type="button"
                                                                                                className="btn btn-add-remove"
                                                                                                style={{
                                                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                                                }}
                                                                                            >
                                                                                                <span className="btn-dec">-</span>
                                                                                                <Ink duration="500" />
                                                                                            </button>                                                                      

                                                                                            <input type="text" readOnly value={ cartProducts.find(
                                                                                                (cp) =>
                                                                                                    cp.id ===
                                                                                                    item.id
                                                                                            ).quantity} />
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-add-remove"
                                                                                                style={{
                                                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    removeProduct(item);
                                                                                                    this.forceStateUpdate();
                                                                                                }}
                                                                                            >
                                                                                                <span className="btn-dec">-</span>
                                                                                                <Ink duration="500" />
                                                                                            </button>                                                                            

                                                                                            <input type="text" readOnly value={ cartProducts.find(
                                                                                                (cp) =>
                                                                                                    cp.id ===
                                                                                                    item.id
                                                                                            ).quantity} />
                                                                                        </React.Fragment>
                                                                                    )}

                                                                                    {item.addon_categories.length ? (
                                                                                        <Customization
                                                                                            product={item}
                                                                                            addProduct={addProduct}
                                                                                            forceUpdate={this.forceStateUpdate}
                                                                                        />
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            <span className="btn-inc">+</span>
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        {item.has_variant && item.variants.length > 0 ? (
                                                                            <Variations
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                cartProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                doForceUpdate={this.state.update}
                                                                                addByDefault={true}
                                                                                showDefaultVariant={true}
                                                                            />
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="btn-group btn-group-sm"
                                                                                    role="group"
                                                                                    aria-label="btnGroupIcons1"
                                                                                >
                                                                                    {item.addon_categories.length ? (
                                                                                        <Customization
                                                                                            product={item}
                                                                                            addProduct={addProduct}
                                                                                            forceUpdate={this.forceStateUpdate}
                                                                                        />
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove btn-custom-add"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            Add
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="text-danger text-item-not-available">
                                                                {localStorage.getItem("cartItemNotAvailable")}
                                                            </div>
                                                        )}
                                                        {item.addon_categories.length > 0 && (
                                                            <React.Fragment>
                                                                <span
                                                                    className="customizable-item-text d-block text-center"
                                                                    style={{
                                                                        color: localStorage.getItem("storeColor"),
                                                                    }}
                                                                >
                                                                    {localStorage.getItem("customizableItemText")}
                                                                </span>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    {this.state.filtered_featured_section !== null && this.state.filtered_featured_section.items.map((item, index) => (
                                        <React.Fragment key={item.id}>
                                            <div className="single-deal-section mb-25">
                                                {!this.__itemAvailability(item) && (
                                                    <div className="blur-content-grid">{localStorage.getItem('outOfStockText')}</div>
                                                )}
                                                <div className={`deal-content ${!this.__itemAvailability(item) ? 'blur-content-section' : ''}`}>
                                                    <div className="item-favorites m-5">
                                                        <span className="item-ribbon text-left"
                                                            style={{
                                                                visibility: !this.__getDiscountPercentage(item) ? "hidden" : "" 
                                                            }}
                                                        >
                                                            {this.__getDiscountPercentage(item) ? this.__getDiscountPercentage(item) : '&nbsp;'}% OFF
                                                        </span>
                                                        {user.success && (
                                                            <span
                                                                onClick={(event) => this.setFavoriteItem(item, event)}
                                                                className={`item-favorite text-right heart ${item.is_favorited && "is-active"} heartIcon` + item.id}
                                                            />
                                                        )}
                                                    </div>
                                                    <DelayLink
                                                        to={"../stores/" + item.restaurant_slug + "/" + item.id}
                                                        delay={200}
                                                    >
                                                        <div className="item-image-section">
                                                            <img
                                                                src={item.image}
                                                                alt={item.name}
                                                                className="slider-wrapper__img slider-cust-img"
                                                            />
                                                        </div>
                                                        <div className="item-details">
                                                            <div className="font-w600 text-left">
                                                                <span className="item-title">
                                                                    <strong>
                                                                        {item.name.length > 15 ? `${item.name.substring(0, 15)}...` : item.name}
                                                                    </strong>
                                                                </span>
                                                                {item.has_variant ? (                                                    
                                                                    <span className="item-subtitle text-normal" style={{ 
                                                                        visibility: "hidden"
                                                                        }}>                                                                
                                                                        {item.variants.find(function(o) { return o.is_default === 1 } ).name}
                                                                    </span>
                                                                ) : (                                                
                                                                    <span className="item-subtitle text-normal">&nbsp;</span>
                                                                )}
                                                            </div>
                                                            <div className="item-badge">
                                                                {localStorage.getItem("showVegNonVegBadge") === "true" && item.is_veg !== null && (
                                                                    <React.Fragment>
                                                                        {item.is_veg ? (
                                                                            <img
                                                                                src="/assets/img/various/veg-icon-bg.png"
                                                                                alt="Veg"
                                                                                className="mr-1 veg-non-veg-badge"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="/assets/img/various/non-veg-icon-bg.png"
                                                                                alt="Non-Veg"
                                                                                className="mr-1 veg-non-veg-badge"
                                                                            />
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                            <div className="item-color-price font-w600 text-left mt-10">   
                                                                {item.has_variant ? (       
                                                                    <React.Fragment>
                                                                        <span className="sale-price">                                                                
                                                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                localStorage.getItem("currencyFormat")}{" "}
                                                                                {this.__getSalePrice(item.variants)}
                                                                        </span>     
                                                                        {this.__getRegularPrice(item.variants) && (                                                   
                                                                            <span className="regular-price">       
                                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                    localStorage.getItem("currencyFormat")}
                                                                                    <s>{this.__getRegularPrice(item.variants)}</s>
                                                                            </span> 
                                                                        )} 
                                                                    </React.Fragment>                                             
                                                                ) : (                                                    
                                                                    <span>
                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                            localStorage.getItem("currencyFormat")}{" "}
                                                                            {this.__formatPrice(item.price)}
                                                                        {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                                            localStorage.getItem("currencyFormat")}
                                                                    </span>
                                                                )}     
                                                            </div>
                                                        </div>
                                                        <Ink duration="500" hasTouch={false} />
                                                    </DelayLink>
                                                
                                                    <div className="item-actions">
                                                        {item.is_active ? (
                                                            <React.Fragment>
                                                                {isItemExistInCart(item.id) ? (
                                                                    <React.Fragment>
                                                                        {item.has_variant && item.variants.length > 0 ? (
                                                                            <Variations
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                cartProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                doForceUpdate={this.state.update}
                                                                                addByDefault={true}
                                                                                showDefaultVariant={true}
                                                                            />
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="btn-group btn-group-sm"
                                                                                    role="group"
                                                                                    aria-label="btnGroupIcons1"
                                                                                >
                                                                                    {item.addon_categories.length ? (
                                                                                        <React.Fragment>
                                                                                            <button
                                                                                                disabled
                                                                                                type="button"
                                                                                                className="btn btn-add-remove"
                                                                                                style={{
                                                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                                                }}
                                                                                            >
                                                                                                <span className="btn-dec">-</span>
                                                                                                <Ink duration="500" />
                                                                                            </button>                                                                      

                                                                                            <input type="text" readOnly value={ cartProducts.find(
                                                                                                (cp) =>
                                                                                                    cp.id ===
                                                                                                    item.id
                                                                                            ).quantity} />
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-add-remove"
                                                                                                style={{
                                                                                                    color: localStorage.getItem("cartColor-bg"),
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    removeProduct(item);
                                                                                                    this.forceStateUpdate();
                                                                                                }}
                                                                                            >
                                                                                                <span className="btn-dec">-</span>
                                                                                                <Ink duration="500" />
                                                                                            </button>                                                                            

                                                                                            <input type="text" readOnly value={ cartProducts.find(
                                                                                                (cp) =>
                                                                                                    cp.id ===
                                                                                                    item.id
                                                                                            ).quantity} />
                                                                                        </React.Fragment>
                                                                                    )}

                                                                                    {item.addon_categories.length ? (
                                                                                        <Customization
                                                                                            product={item}
                                                                                            addProduct={addProduct}
                                                                                            forceUpdate={this.forceStateUpdate}
                                                                                        />
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            <span className="btn-inc">+</span>
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        {item.has_variant && item.variants.length > 0 ? (
                                                                            <Variations
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                cartProducts={cartProducts}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                doForceUpdate={this.state.update}
                                                                                addByDefault={true}
                                                                                showDefaultVariant={true}
                                                                            />
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <div
                                                                                    className="btn-group btn-group-sm"
                                                                                    role="group"
                                                                                    aria-label="btnGroupIcons1"
                                                                                >
                                                                                    {item.addon_categories.length ? (
                                                                                        <Customization
                                                                                            product={item}
                                                                                            addProduct={addProduct}
                                                                                            forceUpdate={this.forceStateUpdate}
                                                                                        />
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-add-remove btn-custom-add"
                                                                                            style={{
                                                                                                color: localStorage.getItem("cartColor-bg"),
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}
                                                                                        >
                                                                                            Add
                                                                                            <Ink duration="500" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="text-danger text-item-not-available">
                                                                {localStorage.getItem("cartItemNotAvailable")}
                                                            </div>
                                                        )}
                                                        {item.addon_categories.length > 0 && (
                                                            <React.Fragment>
                                                                <span
                                                                    className="customizable-item-text d-block text-center"
                                                                    style={{
                                                                        color: localStorage.getItem("storeColor"),
                                                                    }}
                                                                >
                                                                    {localStorage.getItem("customizableItemText")}
                                                                </span>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
	cartProducts: state.cart.products,
	user: state.user.user
});

export default withRouter(
	connect(
		mapStateToProps,
		{
            addProduct, 
            removeProduct,            
            setFavoriteItem
		}
	)(DynamicFeaturedSectionStyle1)
);

// export default withRouter(DynamicFeaturedSectionStyle1);
