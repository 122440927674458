import * as firebase from "firebase/app";

import React, { Component } from "react";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";
import CarouselSlider from "./CarouselSlider";
import { Redirect } from "react-router";
// import RestaurantList from "./RestaurantList";
import CategoryList from "./CategoryList";
import DealOfTheDayList from "./DealOfTheDayList";
import FrequentlyBoughtList from "./FrequentlyBoughtList";
import { connect } from "react-redux";
import { getPromoSlides } from "../../../services/promoSlider/actions";
import { getDynamicFeaturedSections } from "../../../services/dynamicFeaturedSection/actions";

import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import { getUserNotifications } from "../../../services/alert/actions";
import { resetInfo, resetItems, resetBackup } from "../../../services/items/actions";
import Ink from "react-ink";

import { Link } from "react-router-dom";
import NoAreaAlert from "./NoAreaAlert";
import Combos from "./Combos";
import DynamicFeaturedSectionStyle1 from "./DynamicFeaturedSectionStyle1";
import DynamicFeaturedSectionStyle2 from "./DynamicFeaturedSectionStyle2";
// import moment from "moment";

class HomeV1 extends Component {
	state = {
		update: true,
        no_area: false,
        restaurant_items_update: false
	};
    
	static contextTypes = {
		router: () => null,
	};

	async componentDidMount() {
		this.props.resetItems();
		this.props.resetInfo();
		this.props.resetBackup();

		const { user } = this.props;

		//if currentLocation doesnt exists in localstorage then redirect the user to firstscreen
		//else make API calls
		if (localStorage.getItem("userSetAddress") !== null) {
			// this.context.router.history.push("/search-location");
			// console.log("Redirect to search location");
			// return <Redirect to="/search-location" />;
			if (localStorage.getItem("showPromoSlider") === "true") {
				const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

				this.props.getDynamicFeaturedSections(userSetAddress.lat, userSetAddress.lng);
				this.props.getPromoSlides(userSetAddress.lat, userSetAddress.lng);
			}

			const { user } = this.props;

			if (user.success) {
				this.props.getUserNotifications(user.data.id, user.data.auth_token);
			}
		} else {
			//call to promoSlider API to fetch the slides
		}

		if (user.success) {
			if (localStorage.getItem("enablePushNotification") === "true") {
				if (firebase.messaging.isSupported()) {
					// const today = moment().toDate();

					// console.log("TODAY", today);
					// const lastSavedNotificationToken = moment(localStorage.getItem("lastSavedNotificationToken"));
					// const days = moment(today).diff(lastSavedNotificationToken, "days");

					// console.log("DAYS", days);

					// const callForNotificationToken = isNaN(days) || days >= 5;

					// console.log(callForNotificationToken);
					// if (callForNotificationToken) {
					let handler = this.props.saveNotificationToken;
					messaging
						.requestPermission()
						.then(async function() {
							const push_token = await messaging.getToken();
							handler(push_token, user.data.id, user.data.auth_token);
							// localStorage.setItem("lastSavedNotificationToken", today);
						})
						.catch(function(err) {
							console.log("Unable to get permission to notify.", err);
						});
					// }
				}
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.languages !== nextProps.languages) {
			if (localStorage.getItem("userPreferedLanguage")) {
				this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage"));
			} else {
				if (nextProps.languages.length) {
					// console.log("Fetching Translation Data...");
					const id = nextProps.languages.filter((lang) => lang.is_default === 1)[0].id;
					this.props.getSingleLanguageData(id);
				}
			}
		}
	}

	componentWillUnmount() {
		// navigator.serviceWorker.removeEventListener("message", message => console.log(message));
	}

    noAreaAlert = (no_area) => {
        this.setState({ no_area: no_area });
    }

	forceStateUpdate = () => {
        if (this.state.update) {
            this.setState({ update: false });
        } else {
            this.setState({ update: true });
        }

        return this.state.update;
	};

	updateRestaurantItemsState = (action) => {
        this.setState({ restaurant_items_update: action });
	};

	render() {
		if (window.innerWidth > 768) {
			return <Redirect to="/" />;
		}

		if (localStorage.getItem("userSetAddress") === null) {
			// this.context.router.history.push("/search-location");
			// console.log("Redirect to search location");
			return <Redirect to="/search-location" />;
		}

		const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
		if (Object.keys(userSetAddress).length === 0 && userSetAddress.constructor === Object) {
			return <Redirect to="/search-location" />;
		}

		const { history, user, promo_slides, dynamic_featured_sections } = this.props;

        const afterCategorySliders = promo_slides.otherSlides !== undefined ? promo_slides.otherSlides.filter(slide => slide.promo_slider.position_id === 1) : [];
        const afterDealofthedaySliders = promo_slides.otherSlides !== undefined ? promo_slides.otherSlides.filter(slide => slide.promo_slider.position_id === 2) : [];
        const afterFrequentlyBoughtSliders = promo_slides.otherSlides !== undefined ? promo_slides.otherSlides.filter(slide => slide.promo_slider.position_id === 3) : [];

		// console.log(promo_slides.mainSlides.length);

		return (
			<React.Fragment>
				<Meta
					seotitle={localStorage.getItem("seoMetaTitle")}
					seodescription={localStorage.getItem("seoMetaDescription")}
					ogtype="website"
					ogtitle={localStorage.getItem("seoOgTitle")}
					ogdescription={localStorage.getItem("seoOgDescription")}
					ogurl={window.location.href}
					twittertitle={localStorage.getItem("seoTwitterTitle")}
					twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>

				<div className="height-100-percent bg-white mb-50">
					<Nav
						logo={true}
						active_nearme={true}
						disable_back_button={true}
						history={history}
						loggedin={user.success}
					/>

                    <div>
                        <button
                            type="submit"
                            className="btn nav-location nav-home-location truncate-text mb-5"
                            style={{ position: "relative", maxWidth: "100%" }}
                            onClick={() => {
                                this.props.loggedin
                                    ? this.context.router.history.push("/my-addresses")
                                    : this.context.router.history.push("/search-location");
                            }}
                        >
                            {localStorage.getItem("userSetAddress") && (
                                <React.Fragment>
                                    <span className="text-normal deliver-to">{localStorage.getItem('deliveryToText')}</span>
                                    <span className="deliver-to-with-address">
                                        <img src="./assets/img/home/pinned-location.png" alt="location" />
                                        <span>
                                            {JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
                                                <strong className="text-uppercase mr-1">
                                                    {JSON.parse(localStorage.getItem("userSetAddress")).tag}
                                                </strong>
                                            ) : null}
                                        </span>

                                        {JSON.parse(localStorage.getItem("userSetAddress")).house !==
                                        null ? (
                                            <span>
                                                {JSON.parse(localStorage.getItem("userSetAddress")).house
                                                    .length > 12
                                                    ? `${JSON.parse(
                                                            localStorage.getItem("userSetAddress")
                                                        ).house.substring(0, 12)}...`
                                                    : JSON.parse(localStorage.getItem("userSetAddress"))
                                                            .house}
                                            </span>
                                        ) : (
                                            <span>
                                                {JSON.parse(localStorage.getItem("userSetAddress")).address
                                                    .length > 30
                                                    ? `${JSON.parse(
                                                            localStorage.getItem("userSetAddress")
                                                        ).address.substring(0, 30)}...`
                                                    : JSON.parse(localStorage.getItem("userSetAddress"))
                                                            .address}
                                            </span>
                                        )}
                                    </span>
                                </React.Fragment>
                            )}
                            <Ink duration="500" />
                        </button>
                    </div>

                    { this.state.no_area ? (
                        <NoAreaAlert />
                    ) : (
                        <React.Fragment>{
                            localStorage.getItem("mockSearchOnHomepage") === "true" && (
                                <Link to="explore">
                                    <div
                                        className={`mock-search-block px-15 ${
                                            localStorage.getItem("showPromoSlider") === "false"
                                                ? "pt-0"
                                                : "" + promo_slides.mainSlides === "null"
                                                ? "pt-0"
                                                : ""
                                        }`}
                                    >
                                        <div className="px-15 d-flex">
                                            <div>
                                                <img src="./assets/img/home/search-icon.png" alt="search-location" />
                                            </div>
                                            <div>
                                                <span>{localStorage.getItem("mockSearchPlaceholder")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}

                            {localStorage.getItem("customHomeMessage") !== "<p><br></p>" &&
                                localStorage.getItem("customHomeMessage") !== "null" &&
                                (localStorage.getItem("customHomeMessage") !== "" && (
                                    <div
                                        style={{
                                            position: "relative",
                                            background: "#f8f9fa",
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: localStorage.getItem("customHomeMessage"),
                                        }}
                                    />
                                ))}
                            {/* <RestaurantList user={user} slides={promo_slides.otherSlides} /> */}
                            
                            {/* Passing slides as props to PromoSlider */}
                            
                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {promo_slides && promo_slides.mainSlides && promo_slides.mainSlides.length > 0 && (
                                        <CarouselSlider
                                            slides={promo_slides.mainSlides}
                                            size={promo_slides.mainSlides[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                        />
                                    )}
                                </React.Fragment>
                            )}

                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.main_section !== undefined && dynamic_featured_sections.main_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='main' />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='main' />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}
                        
                            <CategoryList noAreaAlert={this.noAreaAlert} user={user} slides={promo_slides.otherSlides} />

                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.category_section !== undefined && dynamic_featured_sections.category_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='category' />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='category' />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}

                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {afterCategorySliders && afterCategorySliders.length > 0 && (
                                        <CarouselSlider
                                            slides={afterCategorySliders}
                                            size={afterCategorySliders[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                            secondarySlider={true}
                                        />
                                    )}
                                </React.Fragment>
                            )}

                            <DealOfTheDayList updateRestaurantItemsState={this.updateRestaurantItemsState} forceRestaurantItemsUpdate={this.state.restaurant_items_update} forceStateUpdate={this.forceStateUpdate} user={user} slides={promo_slides.otherSlides} />

                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.deal_of_the_day_section !== undefined && dynamic_featured_sections.deal_of_the_day_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='deal_of_the_day' />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='deal_of_the_day' />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}

                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {afterDealofthedaySliders && afterDealofthedaySliders.length > 0 && (
                                        <CarouselSlider
                                            slides={afterDealofthedaySliders}
                                            size={afterDealofthedaySliders[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                            secondarySlider={true}
                                        />
                                    )}
                                </React.Fragment>
                            )}

                            <Combos updateRestaurantItemsState={this.updateRestaurantItemsState} forceRestaurantItemsUpdate={this.state.restaurant_items_update} forceStateUpdate={this.forceStateUpdate} user={user} slides={promo_slides.otherSlides} />

                            <FrequentlyBoughtList updateRestaurantItemsState={this.updateRestaurantItemsState} forceRestaurantItemsUpdate={this.state.restaurant_items_update} forceStateUpdate={this.forceStateUpdate} user={user} slides={promo_slides.otherSlides} />
                            
                            {dynamic_featured_sections && dynamic_featured_sections !== undefined && (
                                <React.Fragment>
                                    {dynamic_featured_sections.frequenty_bought_section !== undefined && dynamic_featured_sections.frequenty_bought_section.map(section => (
                                        <React.Fragment key={section.id}>
                                            {section.style == 'style_1' && (
                                                <DynamicFeaturedSectionStyle1 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='frequently_bought' />
                                            )}
                                            {section.style == 'style_2' && (
                                                <DynamicFeaturedSectionStyle2 forceStateUpdate={this.forceStateUpdate} featured_section={section} section_type='frequently_bought' />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}

                            {localStorage.getItem("showPromoSlider") === "true" && (
                                <React.Fragment>
                                    {afterFrequentlyBoughtSliders && afterFrequentlyBoughtSliders.length > 0 && (
                                        <CarouselSlider
                                            slides={afterFrequentlyBoughtSliders}
                                            size={afterFrequentlyBoughtSliders[0]["promo_slider"]["size"]}
                                            show_indicators={promo_slides.mainSlides[0]["promo_slider"]["show_indicators"]}
                                            infinite_loop={promo_slides.mainSlides[0]["promo_slider"]["infinite_loop"]}
                                            auto_play={promo_slides.mainSlides[0]["promo_slider"]["auto_play"]}
                                            secondarySlider={true}
                                        />
                                    )}
                                </React.Fragment>
                            )}

                            <img width="100%" style={{
                                padding: "25px"
                            }} alt="test" src="./assets/img/food-feel-good.png" />
                            <Footer active_nearme={true} />
                        </React.Fragment>
                    )}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
    promo_slides: state.promo_slides.promo_slides,
	dynamic_featured_sections: state.dynamic_featured_sections.dynamic_featured_sections,
	user: state.user.user,
	locations: state.locations.locations,
	languages: state.languages.languages,
});

export default connect(
	mapStateToProps,
	{
		getPromoSlides,
		getDynamicFeaturedSections,
		saveNotificationToken,
		getSingleLanguageData,
		getUserNotifications,
		resetInfo,
		resetItems,
		resetBackup,
	}
)(HomeV1);
