import React, { Component } from "react";
import Ink from "react-ink";

class NoAreaAlert extends Component {    
	static contextTypes = {
		router: () => null,
	};

	render() {
		return (
			<React.Fragment>                
                <div
                    className={"col-sm-12 pt-50 text-center"}
                >
                    <img width="70%" src="/assets/img/various/no-area-found.png" alt="No area found" />
                    <p className="pt-10" style={{ 
                        fontSize: '15pt',
                        fontWeight: '500'
                    }}>
                        {localStorage.getItem("noAreaMessage")}
                    </p>

                    <button
                            type="submit"
                            className="btn btn-update-location mb-5"
                            style={{ position: "relative", maxWidth: "100%" }}
                            onClick={() => {
                                this.context.router.history.push("/search-location");
                            }}
                        >
                        {localStorage.getItem("userSetAddress") && (
                            <React.Fragment>
                                Update Location
                            </React.Fragment>
                        )}
                        <Ink duration="500" />
                    </button>
                </div>
			</React.Fragment>
		);
	}
}

export default NoAreaAlert;
