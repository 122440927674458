import React, { Component } from "react";

import ContentLoader from "react-content-loader";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import  { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

class CarouselSlider extends Component {
	render() {
		const { slides, secondarySlider, show_indicators, auto_play, infinite_loop } = this.props;
		return (
			<React.Fragment>
                <div className="col-sm-12">
                    <div
                        className={
                            secondarySlider
                                ? "slider-wrapper secondary-slider-wrapper"
                                : "slider-wrapper py-10 my-0"
                        }
                    >
                        {slides.length === 0 ? (
                            <ContentLoader
                                height={170}
                                width={400}
                                speed={1.2}
                                primaryColor="#f3f3f3"
                                secondaryColor="#ecebeb"
                            >
                                <rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
                                <rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
                            </ContentLoader>
                        ) : (
                            <Carousel showIndicators={show_indicators} autoPlay={auto_play} showThumbs={false} infiniteLoop={infinite_loop} showArrows={false} showDots={false} centerMode={secondarySlider} showStatus={false}>
                                {slides.map((slide, key) =>
                                    <React.Fragment key={key}>
                                        <a href={slide.url}>
                                            <div>
                                                <img alt={slide.data.name} src={ "../" + slide.data.image } />
                                            </div>
                                        </a>
                                    </React.Fragment>
                                    
                                )}
                            </Carousel>
                        )}
                    </div>
				</div>
			</React.Fragment>
		);
	}
}

export default CarouselSlider;
